<template>
  <div>
    <div class="main-filter__head">
      <div class="block-head">
        <div class="block-head__title">
          <div class="title">
            <div class="d-flex flex-wrap align-items-center mx-n2">
              <div class="px-2">
                <span>выбор</span>
              </div>
              <div class="px-2" style="min-width: 300px;">
                <plugin-select
                  v-model:value="currentMode"
                  :options="modeList"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="block-head__btn"
          v-if="currentMode && currentMode.id === 1"
        >
          <ul class="filter-btns">
            <li class="filter-btns__btn filter-btns__btn--first"
              @click="currentTabView = tabButtonList[ 0 ].id"
              :class="{ 'filter-btns__btn--active': ( tabButtonList[ 0 ].id === currentTabView ) }"
            >3D</li>
            <li class="filter-btns__btn filter-btns__btn--second"
              @click="currentTabView = tabButtonList[ 1 ].id"
              :class="{ 'filter-btns__btn--active': ( tabButtonList[ 1 ].id === currentTabView ) }"
            >Список</li>
          </ul>
        </div>
      </div>
    </div>

    <div
      v-if="currentMode && currentMode.id === 1 && currentTabView"
    >
      <chart-layout
        v-if="currentTabView === 1"
      ></chart-layout>
      
      <grid-layout
        v-else-if="currentTabView === 2 && isFilterReady"
      ></grid-layout>
    </div>

    <div
      v-else-if="currentMode && currentMode.id === 2"
    >
      <parking-chart-layout></parking-chart-layout>
    </div>
      
    <grid-office-layout
      v-else-if="currentMode && currentMode.id === 3"
    ></grid-office-layout>
  </div>
</template>

<script>
  import GridLayout from '@/modules/grid/components/GridLayout'
  import ChartLayout from '@/modules/chart/components/ChartLayout'
  import GridOfficeLayout from '@/modules/grid-office/components/GridOfficeLayout'
  import PluginSelect from '@/modules/plugin/components/PluginSelect'
  import ParkingChartLayout from '@/modules/parking-chart/components/ParkingChartLayout'

  import { isFilterReady } from '@/modules/flat/state'

  export default {
    setup() {
      return {
        isFilterReady
      }
    },

    components: { GridLayout, ChartLayout, PluginSelect, ParkingChartLayout, GridOfficeLayout },

    data() { return {
      tabButtonList: [ { id: 1, text: '3D' }, { id: 2, text: 'Список' } ],
      currentTabView: null,

      
      currentMode: null,
      modeList: [ { id: 1, text: 'квартир' }, { id: 2, text: 'парковок' }, { id: 3, text: 'офисов' } ]
    } },

    mounted() {
      this.currentTabView = this.tabButtonList[ 1 ].id
      this.currentMode = this.modeList[ 0 ]
    }
  }
</script>