import { ref } from 'vue'

import profitbaseReguestService from '../../requests/profitbaseReguestService'

const token = ref( null )

const isAutorazing = ref( true )

const authorize = async () => {
  const response = await profitbaseReguestService.authorize()
  token.value = response.data[ 'access_token' ]
}

export { token, authorize, isAutorazing }