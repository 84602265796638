import { ref, computed, reactive } from 'vue'

const value = ref( false )

const isVisible = computed( () => value.value )

const show = () => {
  value.value = true
  document.body.style.overflow = 'hidden'
}
const hide = () => {
  value.value = false
  document.body.style.overflow = null
}
const toggle = () => value.value = !value.value

const flat = reactive( {} )

export { isVisible, show, hide, toggle, flat }