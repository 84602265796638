<template>
	<div class="house-selection__bottom">
		<div class="map">
      <div
        v-if="facadeIsLoaded && facade"
      >
        <img class="house-selection-img" alt=""
          :src="facade.image"
        >
  
        <svg class="map-svg"
          v-bind:viewBox="`0 0 ${ imgWidth } ${ imgHeight }`"
        >
          <polygon class="map-polygon"
            v-for="area of facade.areas"
            :key="area.id"
            :style="{
              fill: isFloorExist( area ) ? 'transparent' : facade.areaColor,
              stroke: isFloorExist( area ) ? 'transparent' : facade.areaColor,
              'pointer-events': isFloorExist( area ) ? 'none' : 'inherit'
            }"
            :fill-opacity="facade.areaOpacity / 100"
            :points="area.coordinates.map( coordinate => `${ coordinate[0] },${ coordinate[1] }` ).join(' ')"
            @click="selectArea( area )"
          >
            <title>{{ area.floorNumber }} этаж</title>
          </polygon>
        </svg>
      </div>
    </div>
	</div>
</template>

<script>
  import profitbaseReguestService from '@/shared/requests/profitbaseReguestService'
  
  import { token } from '@/shared/axios/profitbase/store'

  import { houseId, selectedArea, houseFloorsCount } from '@/modules/parking/state'

  import { floorList, propertyList } from '@/modules/parking-floor/store'

  export default {
    setup() {
      return { houseId, selectedArea, houseFloorsCount, floorList, propertyList }
    },

    data() { return {
      facade: {},
      facadeIsLoaded: false,
      imgWidth: 0,
      imgHeight: 0
    } },

    methods: {
      selectArea( area ) {
        this.selectedArea = area
      },

      isFloorExist( area ) {
        return !this.floorList.find( floor => floor.number === area.floorNumber )
      }
    },

    mounted() {
      profitbaseReguestService.getFacade( { token: token.value, houseId: this.houseId } )
        .then( response => {
          this.facade = response.data[ 0 ]
          this.facadeIsLoaded = true

          const img = new Image()
          img.addEventListener( 'load', () => {
            [ this.imgWidth, this.imgHeight ] = [ img.naturalWidth, img.naturalHeight ]
          })
          img.src = this.facade.image
        } )

      profitbaseReguestService.getHouseFloorsCount( { token: token.value, houseId: this.houseId } )
        .then( response => {
          this.houseFloorsCount = response.data.data[ 0 ].count
        } )
      
      profitbaseReguestService.getFloorList( { token: token.value, houseId: this.houseId } )
        .then( response => {
          this.floorList = response.data
        } )
        
      profitbaseReguestService.getPropertyList( { token: token.value, houseId: this.houseId } )
        .then( response => {
          this.propertyList = response.data.data
        } )
    }
  }
</script>

<style scoped>
  .map {
    position: relative;
  }

  .map-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleY(-1);
  }

  .map-polygon {
    stroke-width: 1;
    cursor: pointer;
    fill-opacity: 0.4;
  }

  .map-polygon:hover {
    fill-opacity: 0.8;
  }
</style>