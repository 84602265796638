import { computed, ref } from 'vue'

import { token } from '@/shared/axios/profitbase/store'
import { houseId } from '@/modules/house/state'

import profitbaseReguestService from '@/shared/requests/profitbaseReguestService'

import Flat from '../models/Flat'

const priceRangeMin = ref( 1000000 )
const priceRangeMax = ref( 10500000 )

const areaRangeMin = ref( 1 )
const areaRangeMax = ref( 155 )

const floorRangeMin = ref( 1 )
const floorRangeMax = ref( 5 )

const roomsNumberMax = ref( 3 )

const flatList = ref( [] )

const currentPage = ref( 0 )

const perPage = computed( () => 8 )

const roomsNumber = ref( null )

const priceMin = ref( priceRangeMin.value )
const priceMax = ref( priceRangeMax.value )

const areaMin = ref( areaRangeMin.value )
const areaMax = ref( areaRangeMax.value )

const floorMin = ref( floorRangeMin.value )
const floorMax = ref( floorRangeMax.value )

const isLoading = ref( false )

const isFilterReady = ref( false )

const fetchFlatList = async () => {
  isLoading.value = true

  const options = {
    token: token.value,
    houseId: houseId.value,
    full: true,
    status: [ 'AVAILABLE' ]
  }

  if ( roomsNumber.value ) options.rooms = [ roomsNumber.value.value ]

  if ( priceMin.value ) options[ 'price[min]' ] = priceMin.value
  if ( priceMax.value ) options[ 'price[max]' ] = priceMax.value

  if ( areaMin.value ) options[ 'area[min]' ] = areaMin.value
  if ( areaMax.value ) options[ 'area[max]' ] = areaMax.value

  // options.propertyTypeAliases = 'commercial' // typePurpose: "commercial"

  const response = await profitbaseReguestService.getFlatList( options )
  const data = response.data.data
  const flatListWithOffset = data.map( flat => new Flat( flat ) )
  flatList.value = flatList.value.concat( flatListWithOffset )
  isLoading.value = false
}

const flatListFiltered = computed( () => {
  return flatList.value.filter( el => el.floor >= floorMin.value && el.floor <= floorMax.value )
} )

const flatListPaginated = computed( () => {
  const count = ( currentPage.value + 1 ) * perPage.value
  const splicedToPerPage = flatListFiltered.value.slice( 0, count )
  return splicedToPerPage
} )

const isPaginatingAvaliable = computed( () => {
  return flatListPaginated.value < flatListFiltered.value
} )

const presetList = ref( [] )

export {
  flatList, currentPage, perPage, fetchFlatList, roomsNumber, priceMin, priceMax, areaMin, areaMax, floorMin, floorMax, isLoading, flatListPaginated, isPaginatingAvaliable, flatListFiltered, presetList, isFilterReady,
  priceRangeMin, priceRangeMax, areaRangeMin, areaRangeMax, floorRangeMin, floorRangeMax, roomsNumberMax
}