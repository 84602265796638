<template>
  <div>
    <house-layout
      v-if="!selectedArea"
    ></house-layout>

    <floor-layout
      v-else
    ></floor-layout>
  </div>
</template>

<script>
  import HouseLayout from '@/modules/house/components/HouseLayout'
  import FloorLayout from '@/modules/floor/components/FloorLayout'

  import { selectedArea } from '@/modules/house/state'

  export default {
    components: { HouseLayout, FloorLayout },

    setup() {
      return { selectedArea }
    },

    unmounted() {
      this.selectedArea = null
    }
  }
</script>