<template>
	<ul class="param-numbers">
		<li class="param-numbers__item"
      v-for="item of items"
      :key="item.id"
      @click="onItemClick( item )"
    >
			<div class="filter-number "
        :class="{ 'filter-number--active': ( item === value ) }"
      >{{ item.text }}</div>
		</li>
	</ul>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array
      },
      value: {
        type: Object
      },
      clearOnSelectedClick: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      onItemClick( item ) {
        if ( item === this.value ) {
          if ( this.clearOnSelectedClick ) this.$emit( 'update:value', null ) 
        } else {
          this.$emit( 'update:value', item )
        }
      }
    }
  }
</script>

<style scoped>
  .filter-number--active {
    cursor: pointer;
  }
</style>