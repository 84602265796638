import axios from 'axios'

const apiUser = process.env.VUE_APP_PROFITBASE_API_USER

const hostRoot = process.env.VUE_APP_HOST_ROOT

const apiEndpoint = `${ hostRoot }${ apiUser }/api/v4/json`
// const apiEndpoint = `https://${ apiUser }.profitbase.ru/api/v4/json`

const profitbaseAxios = axios.create( { baseURL: apiEndpoint } )

export default profitbaseAxios