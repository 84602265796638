<template>
	<div class="my-container">
		<div class="main-filter">
      <plugin-tabs
        v-if="!isAutorazing"
      ></plugin-tabs>

      <flat-layout
        v-if="!isAutorazing && isDialogVisible && currentFlat"
        :flat="currentFlat"
      ></flat-layout>
    </div>
  </div>
</template>

<script>
  import PluginTabs from './modules/plugin/components/PluginTabs'
  import PluginDialog from './modules/plugin/components/PluginDialog'

  import GridLayout from './modules/grid/components/GridLayout'
  import ChartLayout from './modules/chart/components/ChartLayout'

  import FlatLayout from './modules/flat/components/FlatLayout'

  import { isVisible, hide, flat } from './modules/plugin/components/PluginDialog/state'

  import { token, authorize, isAutorazing } from '@/shared/axios/profitbase/store'

  import propertyTypeList from '@/store/propertyTypeList'

  import {
    presetList,  isLoading as isPresetListLoading, isFilterReady,
    priceRangeMin, priceRangeMax, priceMin, priceMax,
    areaRangeMin, areaRangeMax, areaMin, areaMax,
    floorRangeMax, floorMax,
    roomsNumberMax
  } from './modules/flat/state'

  import { houseId } from '@/modules/house/state'
  
	import profitbaseReguestService from '@/shared/requests/profitbaseReguestService'

  export default {
    components: {
      PluginTabs, PluginDialog,
      GridLayout, ChartLayout, 
      FlatLayout
    },

    setup() {
      const onDialogInput = value => value ? show() : hide()

      return {
        isDialogVisible: isVisible, onDialogInput, currentFlat: flat,
        token, authorize, isAutorazing,
        presetList, isPresetListLoading,
        houseId, isFilterReady,
        priceRangeMin, priceRangeMax, priceMin, priceMax,
        areaRangeMin, areaRangeMax, areaMin, areaMax,
        floorRangeMax, floorMax,
        roomsNumberMax,
        propertyTypeList
      }
    },

    async mounted() {
      // this.isAutorazing = true
      // await this.authorize()

      this.isAutorazing = false

      this.isPresetListLoading = true
      profitbaseReguestService.getPresetList( { token: this.token } )
        .then( response => {
          this.presetList = response.data.data
          this.isPresetListLoading = false
        } )

      let flatPromise = profitbaseReguestService.getFlatList( { token: this.token, houseId: this.houseId } )
      flatPromise.then( response => {
        const flatList = response.data.data

        const priceList = flatList.map( el => el.price.value )
        this.priceRangeMin = this.priceMin = Math.min( ...priceList )
        this.priceRangeMax = this.priceMax = Math.max( ...priceList )

        const areaList = flatList.map( el => el.area.area_total )
        this.areaRangeMin = this.areaMin = Math.min( ...areaList )
        this.areaRangeMax = this.areaMax = Math.max( ...areaList )

        const roomsList = flatList.map( el => el.rooms_amount )
        this.roomsNumberMax = Math.max( ...roomsList )
      } )

      let floorCountPromise = profitbaseReguestService.getHouseFloorsCount( { token: this.token, houseId: this.houseId } )
      floorCountPromise.then( response => {
        const data = response.data.data
        const sectionFloorCountList = data.map( el => el.count )
        this.floorRangeMax = this.floorMax = Math.max( ...sectionFloorCountList )
      } )

      Promise.all( [
        flatPromise, floorCountPromise
      ] )
        .then( () => {
          this.isFilterReady = true
        } )

      profitbaseReguestService.getPropertyTypeList( { params: { access_token: this.token } } )
        .then( response => {
          this.propertyTypeList = response.data
        } )
    }
  }
</script>