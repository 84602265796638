import profitbaseAxios from '@/shared/axios/profitbase'

class profitbaseReguestService {

  static authorize() {
    const body = {
      type: 'api-app',
      credentials: { 'pb_api_key': 'app-605bb3c031398' }
    }
    return profitbaseAxios.post( '/authentication', body )
  }


  static getFlatList( settings ) {
    const options = { params: {
      'access_token': settings.token,
      houseId: settings.houseId,
      limit: settings.limit,
      offset: settings.offset,
      rooms: settings.rooms,
      'price[min]': settings[ 'price[min]' ],
      'price[max]': settings[ 'price[max]' ],
      'area[min]': settings[ 'area[min]' ],
      'area[max]': settings['area[max]'],
      full: settings.full,
      status: settings.status
    } }
    return profitbaseAxios.get( '/property', options )
  }

  static getHouse( { token, houseId } ) {
    const options = { params: {
      'access_token': token,
      id: houseId
    } }
    return profitbaseAxios.get( '/house', options )
  }

  static getHouseList( { token } ) {
    const options = { params: {
      'access_token': token,
    } }
    return profitbaseAxios.get( '/house', options )
  }

  static getHouseFloorsCount( { token, houseId } ) {
    const options = { params: {
      'access_token': token,
      houseId
    } }
    return profitbaseAxios.get( '/house/get-count-floors', options )
  }

  static getFacade( { token, houseId } ) {
    const options = { params: {
      'access_token': token,
      houseId
    } }
    return profitbaseAxios.get( '/facade', options )
  }

  static getFloorList( { token, houseId } ) {
    const options = { params: {
      'access_token': token,
      houseId
    } }
    return profitbaseAxios.get( '/floor', options )
  }

  static getPropertyList( { token, houseId, id, full = false, limit, offset } ) {
    const options = { params: {
      'access_token': token,
      houseId,
      full,
      limit,
      offset
    } }

    if ( id ) options.params.id = id
    
    return profitbaseAxios.get( '/property', options )
  }

  static getPresetList( { token } ) {
    const options = { params: {
      'access_token': token,
    } }
    return profitbaseAxios.get( '/plan', options )
  }

  static getPropertyTypeList ( options ) {
    return profitbaseAxios.get( '/property-types', options )
  }
}

export default profitbaseReguestService