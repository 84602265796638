<template>
  <div class="flat-selection__bottom">
    <div class="flat-block">
      <div class="flat-block__first">
        <div class="flat-block__back">
          <button class="back-btn" @click="onBack">
            <svg
              width="6"
              height="9"
              class="back-btn__icon"
              viewBox="0 0 6 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.29429 0.000154974C5.43186 -0.00277067 5.56695 0.0357894 5.68078 0.110534C5.79462 0.185278 5.88154 0.292512 5.92946 0.417231C5.97739 0.541951 5.98393 0.67799 5.94819 0.806473C5.91245 0.934955 5.83619 1.04943 5.73002 1.13406L1.67913 4.48965L5.73002 7.84405C5.80346 7.89625 5.86497 7.96251 5.91069 8.03875C5.9564 8.11499 5.98535 8.19956 5.99571 8.28712C6.00607 8.37468 5.99762 8.46334 5.97089 8.5476C5.94416 8.63185 5.89973 8.70989 5.84038 8.77677C5.78103 8.84365 5.70803 8.89791 5.62597 8.93625C5.5439 8.97459 5.45453 8.99613 5.36346 8.99952C5.27238 9.00292 5.18156 8.98815 5.09668 8.95604C5.01181 8.92392 4.9347 8.87521 4.87019 8.81295L0.232292 4.97598C0.159459 4.91588 0.100974 4.84129 0.0608706 4.75722C0.0207672 4.67315 0 4.58164 0 4.48906C0 4.39648 0.0207672 4.30497 0.0608706 4.2209C0.100974 4.13683 0.159459 4.06216 0.232292 4.00206L4.87019 0.161404C4.98735 0.0607796 5.13763 0.00357228 5.29429 7.31027e-06V0.000154974Z"
                fill="black"
              />
            </svg>
            Назад
          </button>
        </div>

        <div class="flat-block__title">
          <div class="total-apartments">
            Всего {{ isCommercialFloor ? `офисов` : `квартир` }}:
            {{
              propertiesListOfFloor.filter((el) =>
                // ["AVAILABLE", "EXECUTION", "SOLD"].includes(el.status)
                ["AVAILABLE", "BOOKED", "SOLD"].includes(el.status)
              ).length
            }}
          </div>
        </div>

        <div class="flat-block__status">
          <ul class="flat-status">
            <li class="flat-status__item">
              <div class="status-item status-item--sale">В продаже</div>
              {{ propertyAvailableListLengthOfFloor }}
            </li>
            <li class="flat-status__item">
              <div class="status-item status-item--booking">Бронь</div>
              <!-- {{ propertyExecutionListLengthOfFloor }} -->
              {{ propertyBookedListLengthOfFloor }}
            </li>
            <li class="flat-status__item">
              <div class="status-item status-item--sales">Продано</div>
              {{ propertySoldListLengthOfFloor }}
            </li>
          </ul>
        </div>

        <div class="flat-block__floors">
          <div class="flat-floors">
            <div class="flat-floors__title">
              <div class="floor-title">Этаж</div>
            </div>
            <ul class="param-numbers">
              <li
                class="param-numbers__item"
                v-for="item of items"
                :key="item.id"
              >
                <div
                  class="floor-number"
                  :class="{
                    'floor-number--active': currentFloor == item.id,
                    'floor-number--disabled': isFloorExist(item),
                  }"
                  @click="currentFloor = item.id"
                >
                  {{ item.text }}
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="flat-block__price">
          <button class="my-btn" @click="onLink">
            Уточнить стоимость {{ isCommercialFloor ? `офиса` : `квартиры` }}
          </button>
        </div>

        <div class="flat-block__save">
          <button class="save-btn" @click="onDownload">
            <svg
              width="23"
              height="26"
              class="save-btn__icon"
              viewBox="0 0 23 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.28613 2.71441C3.28613 1.76766 4.0538 1 5.00054 1H15.287C15.6025 1 15.8585 1.25582 15.8585 1.57154C15.8585 1.88706 15.6025 2.14288 15.287 2.14288H5.00054C4.68502 2.14288 4.42901 2.39889 4.42901 2.71441V13.0009C4.42901 13.3164 4.17318 13.5724 3.85767 13.5724C3.54196 13.5724 3.28613 13.3164 3.28613 13.0009V2.71441ZM22.1447 7.85764C22.4602 7.85764 22.7162 8.11347 22.7162 8.42918V17.5724C22.7162 17.8881 22.4602 18.1439 22.1447 18.1439C21.8291 18.1439 21.5731 17.8881 21.5731 17.5724V8.42918C21.5731 8.11347 21.8291 7.85764 22.1447 7.85764ZM22.1447 20.4302C22.4602 20.4302 22.7162 20.686 22.7162 21.0015V23.2874C22.7162 24.2342 21.9485 25.0019 21.0018 25.0019H6.14322C5.8277 25.0019 5.57188 24.746 5.57188 24.4303C5.57188 24.1148 5.82771 23.859 6.14322 23.859H21.0018C21.3173 23.859 21.5731 23.603 21.5731 23.2874V21.0015C21.5731 20.686 21.8291 20.4302 22.1447 20.4302Z"
                fill="#83493B"
                stroke="#83493B"
                stroke-width="0.5"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.0687 1.04346C15.2823 0.955058 15.5281 1.00404 15.6916 1.16744L22.5492 8.02509C22.7126 8.18849 22.7614 8.43437 22.673 8.64791C22.5846 8.86145 22.3762 9.00055 22.1451 9.00055H16.4302C15.4835 9.00055 14.7158 8.23308 14.7158 7.28614V1.57176C14.7158 1.34062 14.8551 1.13225 15.0687 1.04367L15.0687 1.04346ZM15.859 2.95111V7.28612C15.859 7.60183 16.1148 7.85765 16.4303 7.85765H20.7655L15.859 2.95111Z"
                fill="#83493B"
                stroke="#83493B"
                stroke-width="0.5"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1 16.4299C1 16.1144 1.25602 15.8584 1.57154 15.8584H3.28595C4.23269 15.8584 5.00036 16.6261 5.00036 17.5728C5.00036 18.5198 4.23269 19.2872 3.28595 19.2872H2.14307V21.0016C2.14307 21.3173 1.88706 21.5732 1.57154 21.5732C1.25602 21.5732 1 21.3173 1 21.0016V16.4299ZM2.14307 18.1443H3.28595C3.60147 18.1443 3.85748 17.8885 3.85748 17.5728C3.85748 17.2573 3.60147 17.0015 3.28595 17.0015H2.14307V18.1443Z"
                fill="#83493B"
                stroke="#83493B"
                stroke-width="0.5"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.71484 16.4299C6.71484 16.1144 6.97067 15.8584 7.28638 15.8584H8.42925C10.0072 15.8584 11.2865 17.1377 11.2865 18.7159C11.2865 20.2939 10.0072 21.5732 8.42925 21.5732H7.28638C6.97067 21.5732 6.71484 21.3173 6.71484 21.0016V16.4299ZM7.85772 17.0015V20.4303H8.42925C9.376 20.4303 10.1437 19.6626 10.1437 18.7159C10.1437 17.7689 9.376 17.0015 8.42925 17.0015H7.85772Z"
                fill="#83493B"
                stroke="#83493B"
                stroke-width="0.5"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.001 16.4299C13.001 16.1144 13.257 15.8584 13.5725 15.8584H16.4298C16.7455 15.8584 17.0013 16.1144 17.0013 16.4299C17.0013 16.7455 16.7455 17.0015 16.4298 17.0015H14.144V18.7159H15.8585C16.174 18.7159 16.4298 18.9717 16.4298 19.2872C16.4298 19.6029 16.174 19.8588 15.8585 19.8588H14.144V21.0016C14.144 21.3173 13.888 21.5732 13.5725 21.5732C13.257 21.5732 13.001 21.3173 13.001 21.0016V16.4299Z"
                fill="#83493B"
                stroke="#83493B"
                stroke-width="0.5"
              />
            </svg>
            Скачать план
          </button>
        </div>
      </div>
      <div class="flat-block__section">
        <div class="map">
          <div v-if="floor">
            <img class="flat-selection-img" alt="" :src="floor.images.big" />

            <svg
              class="map-svg"
              v-bind:viewBox="`0 0 ${floor.originalLayoutWidth} ${floor.originalLayoutHeight}`"
            >
              <polygon
                class="map-polygon"
                v-for="(area, idx) of floor.areas"
                :key="idx"
                :points="
                  area.coordinates
                    .map((coordinate) => `${coordinate.x},${coordinate.y}`)
                    .join(' ')
                "
                :style="{
                  fill: isPropertyAvailable(area)
                    ? 'green'
                    : isPropertyBooked(area)
                    ? 'yellow'
                    : isPropertySold(area)
                    ? 'gray'
                    : 'transparent',
                  stroke: isPropertyAvailable(area)
                    ? 'green'
                    : isPropertyBooked(area)
                    ? 'yellow'
                    : isPropertySold(area)
                    ? 'gray'
                    : 'transparent',
                  'pointer-events': isPropertyAvailable(area)
                    ? 'auto'
                    : isPropertyBooked(area)
                    ? 'none'
                    : isPropertySold(area)
                    ? 'none'
                    : 'none',
                }"
                @click="selectArea(area)"
              >
                <title>
                  Площадь
                  {{
                    this.propertiesListOfFloor.find(
                      (property) => property.id === area.propertyId
                    )?.area?.area_total
                  }}
                  кв.м.
                </title>
              </polygon>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { houseId, selectedArea, houseFloorsCount } from "@/modules/house/state";
import { token } from "@/shared/axios/profitbase/store";
import { floorList, propertyList } from "@/modules/floor/store";
import { show, flat } from "@/modules/plugin/components/PluginDialog/state";

export default {
  setup() {
    return {
      houseId,
      selectedArea,
      houseFloorsCount,
      token,
      floorList,
      propertyList,
      currentFlat: flat,
      showDialog: show,
    };
  },

  data() {
    return {
      currentFloor: this.selectedArea.floorNumber,
    };
  },

  methods: {
    onBack() {
      this.selectedArea = null;
    },

    onLink() {
      const el = document.querySelector('a[href="#popup:myform"]');
      if (el) el.click();
      link.remove();
    },

    isFloorExist(floor) {
      return !this.floorList.find((el) => el.number === floor.id);
    },

    onDownload() {
      const link = document.createElement("a");
      link.href = `${process.env.VUE_APP_HOST_ROOT}${process.env.VUE_APP_PROFITBASE_API_USER}/build-pdf/${this.houseId}/${this.floor.number}`;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    isPropertyAvailable(area) {
      const property = this.propertiesListOfFloor.find(
        (property) => property.id === area.propertyId
      );
      return property ? property.status === "AVAILABLE" : false;
    },

    isPropertyExecutoion(area) {
      const property = this.propertiesListOfFloor.find(
        (property) => property.id === area.propertyId
      );
      return property ? property.status === "EXECUTION" : false;
    },

    isPropertyBooked(area) {
      const property = this.propertiesListOfFloor.find(
        (property) => property.id === area.propertyId
      );
      return property ? property.status === "BOOKED" : false;
    },

    isPropertySold(area) {
      const property = this.propertiesListOfFloor.find(
        (property) => property.id === area.propertyId
      );
      return property ? property.status === "SOLD" : false;
    },

    selectArea(area) {
      const property = this.propertiesListOfFloor.find(
        (property) => property.id === area.propertyId
      );
      this.currentFlat.value = property;
      this.showDialog();

      // profitbaseAxios.get( '/plan', {
      //   params: { 'access_token': this.token, houseId: this.houseId }
      // } )
      //   .then( response => {
      //     const presetList = response.data.data
      //     const preset = presetList.find( el => el.properties ? el.properties.includes( `${ property.id }` ) : false )

      //     if ( !preset ) return

      //     this.currentFlat.value = preset
      //     this.showDialog()
      //   } )
    },
  },

  computed: {
    items() {
      const result = [];
      for (let i = 1; i <= this.houseFloorsCount; i++) {
        result.push({ id: i, text: i });
      }
      return result;
    },

    floor() {
      return this.floorList.find((floor) => floor.number === this.currentFloor);
    },

    propertiesListOfFloor() {
      return this.propertyList.filter(
        (property) => property.floor === this.floor.number
      );
    },

    propertyAvailableListLengthOfFloor() {
      return this.propertiesListOfFloor.filter(
        (property) => property.status === "AVAILABLE"
      ).length;
    },

    propertyExecutionListLengthOfFloor() {
      return this.propertiesListOfFloor.filter(
        (property) => property.status === "EXECUTION"
      ).length;
    },

    propertyBookedListLengthOfFloor() {
      return this.propertiesListOfFloor.filter(
        (property) => property.status === "BOOKED"
      ).length;
    },

    propertySoldListLengthOfFloor() {
      return this.propertiesListOfFloor.filter(
        (property) => property.status === "SOLD"
      ).length;
    },

    isCommercialFloor() {
      return !!this.propertiesListOfFloor.filter(
        (property) => property.typePurpose === "commercial"
      ).length;
    },
  },

  mounted() {},
};
</script>

<style scoped>
.floor-number--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.flat-block__section {
  align-items: center;
}

.map {
  position: relative;
}

.map-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map-polygon {
  stroke-width: 1;
  cursor: pointer;
  fill-opacity: 0.4;
}

.map-polygon:hover {
  fill-opacity: 0.8;
}

.floor-number--active {
  cursor: pointer;
}
</style>
