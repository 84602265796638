<template>
  <div class="overlay"
    v-if="isVisible"
  >
    <div class="overlay-inner">
      <div class="my-modal">
        <div class="flat-block flat-block--reverse">
          <div class="flat-block__first flat-block__first--large">
            <div class="my-modal__close">
              <svg class="my-modal-icon" width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                @click="$emit( 'update:isVisible', false )"
              >
                <path d="M1.89686 21.8058C1.68704 21.8059 1.48192 21.7438 1.30745 21.6273C1.13298 21.5109 0.996991 21.3455 0.916692 21.1518C0.836392 20.9582 0.815386 20.7452 0.85633 20.5396C0.897275 20.3341 0.99833 20.1453 1.14671 19.9972L20.7488 0.418621C20.9478 0.219911 21.2176 0.108276 21.499 0.108276C21.7803 0.108276 22.0502 0.219911 22.2491 0.418621C22.4481 0.617332 22.5598 0.886841 22.5598 1.16786C22.5598 1.44888 22.4481 1.71839 22.2491 1.9171L2.647 21.4956C2.54858 21.5941 2.43165 21.6722 2.30291 21.7255C2.17418 21.7787 2.03619 21.806 1.89686 21.8058Z" fill="#83493B"/>
                <path d="M21.4989 21.8058C21.3596 21.806 21.2216 21.7787 21.0929 21.7255C20.9641 21.6722 20.8472 21.5941 20.7488 21.4956L1.14666 1.9171C0.947706 1.71839 0.835938 1.44888 0.835938 1.16786C0.835938 0.886841 0.947706 0.617332 1.14666 0.418621C1.34561 0.219911 1.61544 0.108276 1.8968 0.108276C2.17816 0.108276 2.44799 0.219911 2.64694 0.418621L22.2491 19.9972C22.3974 20.1453 22.4985 20.3341 22.5394 20.5396C22.5804 20.7452 22.5594 20.9582 22.4791 21.1518C22.3988 21.3455 22.2628 21.5109 22.0883 21.6273C21.9139 21.7438 21.7087 21.8059 21.4989 21.8058Z" fill="#83493B"/>
              </svg>
            </div>
            <slot name="content"></slot>
          </div>
          <div class="flat-block__section">
            <slot name="preview"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped>
  .overlay {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    overflow-y: auto;
    height: 100vh;
  }

  .overlay-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .my-modal-icon {
    cursor: pointer;
  }
</style>