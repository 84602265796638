<template>
  <div class="plugin-range">
    <input type="text" class="js-range-slider" name="my_range" value=""
      ref="input-range"
    >
  </div>
</template>

<script>
  import ionRangeSlider from 'ion-rangeslider'

  export default {
    props: {
      min: {
        type: Number
      },
      max: {
        type: Number
      },
      value: {
        type: Array
      }
    },

    methods: {
      onChange( data ) {
        this.$emit( 'update:value', [ data.from, data.to ] )
      }
    },

    mounted() {
			$( this.$refs[ 'input-range' ] ).ionRangeSlider({
				type: "double",
				skin: "round",
				grid: false,
				min: this.min,
				max: this.max,
				from: this.value[ 0 ],
				to: this.value[ 1 ],
				hide_from_to: true,
				hide_min_max: true,
				onChange: this.onChange
			});
    }
  }
</script>

<style>
  .plugin-range .irs-bar, .plugin-range .irs-handle {
    cursor: pointer;
  }
</style>