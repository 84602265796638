<template>
  <div>
    <flat-filter></flat-filter>
  
    <flat-list></flat-list>
  </div>
</template>

<script>
  import FlatFilter from '@/modules/flat/components/FlatFilter'
  import FlatList from '@/modules/flat/components/FlatList'

  export default {
    components: { FlatFilter, FlatList }
  }
</script>