<template>
  <div>
    <parking-layout
      v-if="!selectedArea"
    ></parking-layout>

    <parking-floor-layout
      v-else
    ></parking-floor-layout>
  </div>
</template>

<script>
  import ParkingLayout from '@/modules/parking/components/ParkingLayout'
  import ParkingFloorLayout from '@/modules/parking-floor/components/ParkingFloorLayout'

  import { selectedArea } from '@/modules/parking/state'

  export default {
    components: { ParkingLayout, ParkingFloorLayout },

    setup() {
      return { selectedArea }
    },

    unmounted() {
      this.selectedArea = null
    }
  }
</script>