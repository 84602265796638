<template>
  <div class="plugin-select" tabindex="-1"
    @blur="onBlur"
    @click="onClick"
  >
    <div class="plugin-select__wrapper"
      :class="{ 'plugin-select__wrapper_active': isActive }"
    >
      <div class="d-flex align-items-center justify-content-between mn-2">
        <div class="px-2">
          <span>{{ value ? value.text : '&nbsp;' }}</span>
        </div>
        <div class="px-2">
          <svg class="d-block" width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg"
            :class="{ 'plugin-select__icon_active': isActive }"
          >
            <path d="M26.9995 1.81967C27.0083 1.46495 26.8926 1.11662 26.6684 0.823098C26.4442 0.529576 26.1225 0.305448 25.7483 0.181876C25.3742 0.058304 24.9661 0.0414272 24.5806 0.133592C24.1952 0.225756 23.8518 0.422382 23.5979 0.696134L13.5313 11.1413L3.46826 0.696133C3.31169 0.506766 3.11291 0.348175 2.88419 0.230292C2.65547 0.11241 2.40176 0.0377758 2.13909 0.0110629C1.87642 -0.0156499 1.61043 0.00613468 1.35767 0.0750521C1.10491 0.143969 0.870804 0.258534 0.67016 0.411574C0.469517 0.564613 0.306761 0.75283 0.191738 0.964436C0.0767154 1.17604 0.0121135 1.40648 0.00191871 1.64132C-0.00827417 1.87616 0.0360412 2.11034 0.132378 2.32919C0.228716 2.54805 0.374847 2.74686 0.561628 2.91319L12.0723 14.872C12.2526 15.0598 12.4764 15.2106 12.7286 15.314C12.9808 15.4174 13.2553 15.4709 13.5331 15.4709C13.8108 15.4709 14.0853 15.4174 14.3375 15.314C14.5897 15.2106 14.8137 15.0598 14.994 14.872L26.5158 2.91319C26.8177 2.61109 26.9893 2.22361 27 1.81967L26.9995 1.81967Z" fill="white"/>
          </svg>
        </div>
      </div>

      <ul class="plugin-select__list"
        v-if="isActive"
      >
        <li class="plugin-select__list-item"
          v-for="option of options.filter( option => option !== value )"
          :key="option.id"
          @click="onSelect( option )"
        >
          <span>{{ option.text }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {},
      options: { type: Array }
    },

    data() { return {
      isActive: false
    } },

    methods: {
      onBlur() { this.isActive = false },

      onClick() { this.isActive = !this.isActive },

      onSelect( option ) {
        this.$emit( 'update:value', option )
      }
    }
  }
</script>

<style scoped lang="scss">
  .plugin-select {
    cursor: pointer;
    user-select: none;
    position: relative;
    z-index: 2;

    &__wrapper {
      padding: 5px 12.5px;
      border: 1px solid #FFFFFF;
      border-radius: 7px;

      &_active {
        border-radius: 0;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }
    }

    &__icon_active {
      transform: scaleY(-1);
    }

    &__list {
      list-style: none;
      padding: 0;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: auto;
      border: 1px solid #FFFFFF;
      border-top: none;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      background-color: rgba( 0, 0, 0, .875 );

      &-item {
        padding: 5px 12.5px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>