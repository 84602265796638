<template>
	<div class="main-filter__list">
    <div class="filter-items"
      v-if="isFlatListLoading"
    >
      <p class="param-head">Загрузка...</p>
    </div>
		<div class="filter-items"
      v-else-if="flatList.length"
    >
      <flat-card
        v-for="flatItem of flatListPaginated"
        :key="flatItem.id"
        :flat="flatItem"
      ></flat-card>
		</div>
    <div class="filter-items"
      v-else
    >
      <p class="param-head">По вашему запросу помещений не найдено</p>
    </div>

		<div class="show-more"
      v-if="flatList.length && isPaginatingAvaliable"
    >
			<button class="btn-load"
        @click="showMore"
      >
				<svg width="24" height="20" class="btn-load__icon" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M6.5195 10.5966L4.26438 8.34149C4.11374 8.19072 3.91648 8.11708 3.71934 8.11708C3.5222 8.11708 3.32493 8.19072 3.17429 8.34149L0.919166 10.5966C0.620043 10.8968 0.620043 11.3819 0.919166 11.681C1.21842 11.9801 1.7034 11.9801 2.00371 11.681L2.92499 10.7597C3.30797 15.6336 7.37505 19.4717 12.3476 19.4717C15.9793 19.4717 19.1287 17.423 20.7139 14.421C20.9133 14.047 20.7727 13.5835 20.3999 13.3841C20.0272 13.1858 19.5637 13.3274 19.3642 13.7002H19.3619C18.0339 16.2193 15.3935 17.9383 12.3476 17.9383C8.20099 17.9383 4.80497 14.7551 4.45477 10.7005L5.43505 11.6807C5.73525 11.9799 6.22034 11.9799 6.51946 11.6807C6.81858 11.3816 6.81858 10.8965 6.51946 10.5963L6.5195 10.5966ZM23.7752 9.42631C24.0754 9.12719 24.0754 8.64208 23.7752 8.34177C23.4759 8.04264 22.9909 8.04264 22.6906 8.34177L21.7693 9.26305C21.3874 4.38918 17.3193 0.551111 12.3481 0.551111C8.71502 0.551111 5.56599 2.60104 3.97944 5.60421H3.98065H3.97944C3.78002 5.97804 3.92166 6.44151 4.29563 6.64107C4.66853 6.84049 5.13307 6.69885 5.33249 6.32596V6.32367H5.3337C6.66067 3.80462 9.30215 2.08449 12.3483 2.08449C16.4936 2.08449 19.8906 5.26761 20.2398 9.3234L19.2595 8.34205C18.9604 8.04293 18.4754 8.04293 18.1762 8.34205C17.876 8.64239 17.876 9.12749 18.1762 9.4266L20.4301 11.6816C20.5809 11.8324 20.778 11.906 20.9753 11.906C21.1724 11.906 21.3695 11.8324 21.5203 11.6816L23.7752 9.42631Z" fill="white"/>
				</svg>
				Показать еще
			</button>
		</div>
	</div>
</template>

<script>
  import FlatCard from './FlatCard'

  import { flatList, currentPage, fetchFlatList, roomsNumber, isLoading, flatListPaginated, isPaginatingAvaliable, flatListFiltered, isLoading as isPresetListLoading } from '../../flat/state'

  import { token, authorize } from '@/shared/axios/profitbase/store'

  export default {
    components: { FlatCard },

    setup() {
      return {
        flatList, page: currentPage, roomsNumber, fetchFlatList, isFlatListLoading: isLoading, flatListPaginated, isPaginatingAvaliable, flatListFiltered,
        token, authorize,
        isPresetListLoading
      }
    },

    methods: {
      showMore() {
        this.page++
      }
    },

    async mounted() {
      this.page = 0
      this.flatList = []
      this.roomsNumber = null
      
      await fetchFlatList()
    }
  }
</script>