class Flat {
  constructor( payload ) {
    Object.keys( payload ).forEach( key => {
      this[ key ] = payload[ key ]
    })
    
    const price = payload.custom_fields.find(el => el.id === 'property_price')
    if ( price ) this.price.value = +price?.value
  }
}

export default Flat