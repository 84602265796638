<template>
	<div class="filter-items__item"
		v-if="plan"
	>
		<div class="item">
			<div class="item__top">
				<div class="item__title">
					<div class="item-title"
						:title="`${ plan.code }`"
					>
						<span>{{ plan.roomsAmount }}-к {{ flat.area.area_total }} м<sup>2</sup></span>
					</div>
				</div>
				<div class="item__photo"
          :style="{ backgroundImage: `url('${ plan.image.big }')` }"
        ></div>
			</div>
			<div class="item__bottom">
				<div class="item__price item-price">{{ new Intl.NumberFormat().format( flat.price.value ) }} ₽</div>
				<div class="item__text item-text">Ипотека от <span class="bolder">21 800 ₽</span> в месяц </div>
				<div class="item__items">
					<ul class="item-props">
						<li class="item-props__li">
							<div class="item-prop">
								<svg width="14" height="14" class="item-prop__icon" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0 0V14H14V0H0ZM13.1765 8.23525H4.52964V9.05872H13.1765V13.1762H5.35314V11.1174H4.52966V13.1762H0.823789V9.05872H2.47087V8.23525H0.823789V0.823498H4.94128V4.94099H2.88248V5.76446H11.1177V4.94099H5.76486V0.823498H13.1766L13.1765 8.23525Z" fill="#83493B"/>
								</svg>
								{{ plan.roomsAmount }}-комн
							</div>
						</li>
						<li class="item-props__li">
							<div class="item-prop">
								<svg width="14" height="15" class="item-prop__icon" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M13.6108 0.996094H4.27756C4.06271 0.996094 3.88867 1.17014 3.88867 1.38498V10.7182C3.88867 10.933 4.06271 11.1071 4.27756 11.1071H13.6108C13.8256 11.1071 13.9997 10.933 13.9997 10.7182V1.38498C13.9997 1.17014 13.8256 0.996094 13.6108 0.996094ZM13.2219 10.3293H4.66644V1.77386H13.2219V10.3293Z" fill="#83493B"/>
									<path d="M0.663912 9.27655C0.511895 9.12453 0.265806 9.12453 0.114002 9.27655C-0.0377962 9.42857 -0.0380156 9.67465 0.114002 9.82646L1.28065 10.9931C1.3565 11.0692 1.45589 11.1072 1.5555 11.1072C1.65511 11.1072 1.7545 11.0692 1.83056 10.9933L2.99721 9.82667C3.14923 9.67466 3.14923 9.42857 2.99721 9.27676C2.84519 9.12497 2.59911 9.12475 2.4473 9.27676L1.94439 9.77948V2.32374L2.44711 2.82645C2.52317 2.90252 2.62256 2.94049 2.72217 2.94049C2.82177 2.94049 2.92117 2.90252 2.99723 2.82667C3.14925 2.67465 3.14925 2.42856 2.99723 2.27676L1.83058 1.11011C1.67856 0.958089 1.43247 0.958089 1.28067 1.11011L0.114013 2.27676C-0.0380044 2.42878 -0.0380044 2.67487 0.114013 2.82667C0.266031 2.97847 0.512119 2.97869 0.663923 2.82667L1.16664 2.32395V9.7797L0.663912 9.27655Z" fill="#83493B"/>
									<path d="M12.7195 11.9988C12.5675 11.8468 12.3214 11.8468 12.1696 11.9988C12.0178 12.1508 12.0175 12.3969 12.1696 12.5487L12.6723 13.0514H5.21653L5.71925 12.5487C5.87127 12.3967 5.87127 12.1506 5.71925 11.9988C5.56723 11.847 5.32114 11.8468 5.16934 11.9988L4.00269 13.1654C3.85067 13.3174 3.85067 13.5635 4.00269 13.7153L5.16934 14.882C5.2454 14.9581 5.34479 14.996 5.4444 14.996C5.54401 14.996 5.6434 14.9581 5.71946 14.8822C5.87148 14.7302 5.87148 14.4841 5.71946 14.3323L5.21655 13.8294H12.6723L12.1696 14.3321C12.0176 14.4841 12.0176 14.7302 12.1696 14.882C12.2454 14.9581 12.3448 14.9961 12.4444 14.9961C12.544 14.9961 12.6434 14.9581 12.7195 14.8822L13.8861 13.7156C14.0382 13.5636 14.0382 13.3175 13.8861 13.1657L12.7195 11.9988Z" fill="#83493B"/>
								</svg>
								{{ flat.area.area_total }} м<sup>2</sup>
							</div>
						</li>
						<li class="item-props__li">
							<div class="item-prop">
								<svg width="15" height="14" class="item-prop__icon" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M0.339344 0.518066C0.250454 0.518066 0.162273 0.554639 0.0994228 0.617489C0.0365731 0.680339 0 0.76852 0 0.85741V13.1428C0 13.2316 0.0365731 13.3199 0.0994228 13.3828C0.162273 13.4456 0.250454 13.4822 0.339344 13.4822H14.6052C14.8721 13.4798 15.0028 13.3368 15 13.0882V10.7072C15 10.6183 14.9634 10.5301 14.9005 10.4673C14.8377 10.4044 14.7494 10.3678 14.6606 10.3678H12.6141V8.97828C12.6141 8.88939 12.5776 8.80109 12.5148 8.73824C12.452 8.67539 12.3637 8.63881 12.2748 8.63881H10.2285V6.91949C10.2285 6.8306 10.192 6.7423 10.1291 6.67945C10.0663 6.6166 9.97797 6.58015 9.88908 6.58015H7.84285V4.8877C7.84285 4.79881 7.80627 4.71051 7.74342 4.64766C7.68057 4.58481 7.59228 4.54824 7.50339 4.54836H5.45715V2.84252C5.45715 2.75363 5.42057 2.66533 5.35784 2.60248C5.29499 2.53963 5.20669 2.50305 5.1178 2.50305H3.06457V0.857695C3.06457 0.768805 3.02799 0.680624 2.96514 0.617774C2.90229 0.554924 2.81411 0.518351 2.72522 0.518351L0.339344 0.518066ZM0.678682 1.19686H2.38582V2.78683C2.39091 3.05362 2.53922 3.17377 2.77986 3.18156H4.77828V4.83201C4.79461 5.10874 4.9254 5.21883 5.1717 5.22698H7.1641V6.86382C7.15415 7.13298 7.31122 7.25064 7.55752 7.25879H9.54991V8.92273C9.55595 9.18668 9.69763 9.30955 9.94394 9.31746H11.9357V10.6516C11.9444 10.9313 12.083 11.0383 12.3292 11.0466H14.3215V12.8033H0.678651V1.19695L0.678682 1.19686Z" fill="#83493B"/>
								</svg>
								{{ flat.floor }} этаж
							</div>
						</li>
					</ul>
				</div>
				<div class="item__btn">
					<div class="item-btn">
						<button class="my-btn"
              @click="showDialog"
            >Подробнее</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import { show, flat as currentFlat } from '@/modules/plugin/components/PluginDialog/state'

	import { token } from '@/shared/axios/profitbase/store'

	import { ref } from 'vue'

	import { presetList } from '../state'

  export default {
    props: {
      flat: {
        type: Object
      }
    },

    setup( props ) {
			const plan = ref( null )

      const showDialog = () => {
        currentFlat.value = props.flat
        show()
      }

      return {
        showDialog, token, plan,
				presetList
      }
    },

		computed: {
			plan() {
				const plan = this.presetList.find( el => el.properties ? el.properties.includes( `${ this.flat.id }` ) : false )
				return plan || undefined
			}
		}
  }
</script>

<style scoped>
	.item-title {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.item__text.item-text {
		opacity: 0;
	}

	.item__photo {
		background-size: contain;
	}
</style>